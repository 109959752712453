export function createPairs(data, showHeight) {
  let idHeight = 0
  const pairs = {}
  data.forEach((member) => {
    idHeight = Math.max(member.pairID, idHeight)
    // group members by marriage
    // members are ordered by age in the respective pair
    const key = member.pairID.toString()
    if (key in pairs) {
      if (member.bornIn > pairs[key][0].bornIn) {
        // younger
        pairs[key].push(member)
      } else if (member.bornIn < pairs[key][0].bornIn) {
        // older
        pairs[key].unshift(member)
      } else if (member.gender === 'F') {
        pairs[key].push(member)
      } else {
        pairs[key].unshift(member)
      }
    } else {
      pairs[key] = [member]
    }
  })
  if (showHeight) console.log('pair height', idHeight)
  return pairs
}

export function convertToRoman(num) {
  var roman = {
    M: 1000,
    CM: 900,
    D: 500,
    CD: 400,
    C: 100,
    XC: 90,
    L: 50,
    XL: 40,
    X: 10,
    IX: 9,
    V: 5,
    IV: 4,
    I: 1
  }
  var result = '';
  for (var key in roman) {
    if (num === roman[key]) {
      return result +=key;
    }
    var check = num > roman[key];
    if(check) {
      result = result + key.repeat(parseInt(num/roman[key]));
      num = num%roman[key];
    }
  }
 return result;
}