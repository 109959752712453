import React from 'react'
import injectSheet from 'react-jss'
import ReactGallery from 'react-grid-gallery'

const IMAGES =
[
{
        src: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_b.jpg",
        thumbnail: "https://c2.staticflickr.com/9/8356/28897120681_3b2c0f43e0_n.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212,
        tags: [{value: "Ocean", title: "Ocean"}, {value: "People", title: "People"}],
        caption: "Boats (Jeshu John - designerspics.com)"
},

{
        src: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_b.jpg",
        thumbnail: "https://c4.staticflickr.com/9/8887/28897124891_98c4fdd82b_n.jpg",
        thumbnailWidth: 320,
        thumbnailHeight: 212
}]

function Gallery(props) {
  const { classes} = props
  // supress console in production due to https://github.com/benhowell/react-grid-gallery/issues/83
  console.warn = console.error = () => {}
  return (
    <div className={classes.container}>
      <ReactGallery images={IMAGES} enableImageSelection={false} />
    </div>
  )
}

const styles = {
  container: {
    marginTop: '15px',
  },
}

export default injectSheet(styles)(Gallery)