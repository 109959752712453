import classNames from 'classnames'
import React from 'react'
import injectSheet from 'react-jss'
import { ReactComponent as ArrowIcon } from '../assets/right.svg'

// mock
import Background from '../assets/test.jpg'

function ModalMember(props) {
  const { classes, member, parents, pairs, openModal, isMobile } = props
  let image
  try {
    image = require(`../assets/${member.id}.jpg`)
  } catch (err) {
    image = require(`../assets/placeholder.jpg`)
  }
  const isMale = member.gender === 'M'  
  if (isMobile) {
    return (
      <div className={classes.mobileContainer}>
        <div className={classNames(classes.name, { isMobile })}>
          <div>{member.last}</div>
          <div>{member.first}</div>
        </div>
        <div className={classes.rightMobile}>
            <img className={classNames(classes.profilePic, { isMale, isMobile })} src={image} alt="profile" height={76} width={76}/>
            {
              member.showAge && <span>
                <span className={classNames(classes.year, { isMobile })}>{member.bornIn}
                </span>
                { member.isAgeReal ? null : <span className={classes.estimated}>*</span>}
              </span>
            }
            {
              !member.showAge && <span>
              <span className={classNames(classes.year, { isMobile })}>UNKNOWN</span>
            </span>
            }
        </div>
        <div className={classes.horizontalLineMobile}></div>
        <div className={classNames(classes.parentsContainer, { isMobile })}>
          <div className={classNames(classes.parentLabel, { isMobile })}>Parents</div>
          {
            parents.map(parent => {
              if (typeof parent === 'string') {
                return (
                  <div key={parent}>{parent}</div>
                )
              }
              const fullName = `${parent.last} ${parent.first}`
              return (
                <div key={parent.id}>
                  <span className={classes.parentLink} onClick={() => openModal(pairs[parent.pairID])}>
                    <span>{fullName}</span>
                    <ArrowIcon className={classes.rightArrow}/>
                  </span>
                </div>
              )
            })
          }
          {
            !member.isAgeReal && member.showAge && <span className={classes.footnote}><span className={classes.estimated}>*</span> estimated</span>
          }
        </div>
        <div className={classes.horizontalLineMobile}></div>
        <div className={classNames(classes.descriptionContainer, { isMobile })}>
          <div className={classNames(classes.parentLabel, { isMobile })}>Description</div>
          <span className={classes.desMobile}>{member.description}</span>
        </div>
      </div>
    )
  }

  return (
    <div className={classes.container}>
      <img className={classNames(classes.profilePic, { isMale, isMobile })} src={image} alt="profile" height={96} width={96}/>
      <div className={classes.leftPanel}>
        <div className={classes.name}>
          <div>{member.last}</div>
          <div>{member.first}</div>
        </div>
        <div className={classes.yearContainer}>
          {
            member.showAge && <span className={classes.yearGroup}>
              <span className={classes.year}>{member.bornIn}
              </span>
              { member.isAgeReal ? null : <span className={classes.estimated}>*</span>}
            </span>
          }
          {
            !member.showAge && <span className={classes.yearGroup}>
            <span className={classes.year}>UNKNOWN</span>
          </span>
          }
        </div>
        <div className={classes.horizontalLine}></div>
        <div className={classes.parentsContainer}>
          <div className={classes.parentLabel}>Parents</div>
          {
            parents.map(parent => {
              if (typeof parent === 'string') {
                return (
                  <div key={parent}>{parent}</div>
                )
              }
              const fullName = `${parent.last} ${parent.first}`
              return (
                <div key={parent.id}>
                  <span className={classes.parentLink} onClick={() => openModal(pairs[parent.pairID])}>
                    <span>{fullName}</span>
                    <ArrowIcon className={classes.rightArrow}/>
                  </span>
                </div>
              )
            })
          }
          {
            !member.isAgeReal && member.showAge && <span className={classes.footnote}><span className={classes.estimated}>*</span> estimated</span>
          }
        </div>
        <div className={classes.horizontalLineTwo}></div>
        <div className={classes.descriptionContainer}>
          <div className={classes.parentLabel}>Description</div>
          {member.description}
        </div>
      </div>
      <div className={classes.rightPanel}>
        <img 
          src={`${Background}`}
          alt="background"
          className={classes.image}
        />
      </div>
    </div>
  )
}

const styles = {
  container: {
    background: 'white',
    width: 600,
    height: 320,
    borderRadius: 4,
    boxShadow: '-1px 10px 20px rgba(0,0,0,0.19), -2px 6px 8px rgba(0,0,0,0.23)',
    display: 'flex',
    position: 'relative',
    fontFamily: '"Rubik",sans-serif',
    marginTop: '30px',
  },
  mobileContainer: {
    background: 'white',
    boxShadow: '-1px 10px 20px rgba(0,0,0,0.19), -2px 6px 8px rgba(0,0,0,0.23)',
    width: 320,
    margin: '18px auto 0 auto',
    padding: '0 14px',
    boxSizing: 'border-box',
    position: 'relative',
    fontFamily: 'rubik',
  },
  profilePic: {
    borderRadius: '50%',
    border: '2px solid #ffebee',
    position: 'absolute',
    zIndex: 5,
    left: 240,
    top: 20,
    '&.isMale': {
      border: '2px solid #e8eaf6',
    },
    '&.isMobile': {
      marginBottom: '4px',
      position: 'static'
    },
  },
  leftPanel: {
    width: 300,
    display: 'flex',
    flexDirection: 'column',
    paddingLeft: '18px',
    position: 'relative',
    cursor: 'default',
  },
  name: {
    fontSize: '22px',
    textTransform: 'uppercase',
    marginTop: '16px',
    fontWeight: 600,
    height: '54px',
    '&.isMobile': {
      fontSize: '18px',
      height: 'auto',
    },
  },
  yearContainer: {
    height: '22px',
    position: 'relative',
  },
  rightMobile: {
    position: 'absolute',
    right: 10,
    top: 16,
    display: 'flex',
    alignItems: 'center',
    flexDirection: 'column',
  },
  yearGroup: {
    bottom: 0,
    position: 'absolute',
  },
  year: {
    fontSize: '12px',
    backgroundColor: 'rgb(136, 136, 136)',
    color: 'white',
    padding: '3px 6px',
    borderRadius: '4px',
    letterSpacing: '0.5px',
    '&.isMobile': {
      fontSize: '10px',
    },
  },
  estimated: {
    verticalAlign: 'super',
    color: 'rgb(136, 136, 136)',
  },
  rightPanel: {
    width: 325,
    height: 320,
    overflow: 'hidden',
    transform: `skew(16deg, 0)`,
    position: 'absolute',
    left: 320,
    backfaceVisibility: 'hidden',
  },
  image: {
    width: 340,
    height: 320,
    transform: `skew(-16deg, 0)`,
    position: 'relative',
    left: '-60px',
  },
  footnote: {
    fontSize: '10px',
    position: 'absolute',
    bottom: '8px',
    fontStyle: 'italic',
    color: 'rgb(136, 136, 136)',
  },
  horizontalLine: {
    height: '2px',
    width: '180px',
    borderRadius: '1px',
    backgroundColor: 'rgb(136, 136, 136)',
    opacity: 0.2,
    margin: '14px 0 10px 0',
  },
  horizontalLineMobile: {
    height: '2px',
    width: '180px',
    borderRadius: '1px',
    backgroundColor: 'rgb(136, 136, 136)',
    opacity: 0.2,
    margin: '6px 0 3px 0',
  },
  parentLabel: {
    fontWeight: 600,
    marginBottom: 2,
    fontSize: '16px',
    '&.isMobile': {
      fontSize: '12px',
    },
  },
  parentsContainer: {
    height: 70,
    '&.isMobile': {
      fontSize: '14px',
      height: 65,
    },
  },
  horizontalLineTwo: {
    composes: '$horizontalLine',
    width: '220px',
    margin: '10px 0 10px 0',
  },
  descriptionContainer: {
    fontSize: '14px',
    '&.isMobile': {
      height: '78px',
    },
  },
  desMobile: {
    fontSize: '12px',
  },
  parentLink: {
    cursor: 'pointer',
    padding: '0 10px 0 8px',
    display: 'inline-flex',
    alignItems: 'center',
    borderRadius: '5px',
    transition: 'all 0.3s ease-in-out',
    border: '1px solid #e0e0e0',
    marginBottom: '4px',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
  },
  rightArrow: {
    height: '20px',
    opacity: 0.3,
    marginLeft: '5px',
  },
}

export default injectSheet(styles)(ModalMember)