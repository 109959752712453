import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import injectSheet from 'react-jss'
import { BrowserRouter as Router, Route, Link, Switch } from "react-router-dom"
import { Transition, TransitionGroup } from 'react-transition-group'

import { play, exit } from './Timelines'
import { saveMembers as saveMem, savePairs as saveP } from './actions'
import About from './containers/About'
import Body from './containers/Body'
import Gallery from './containers/Gallery'
import Modal from './containers/Modal'
import Data from './data.json'
import { createPairs } from './utils'
import { ReactComponent as Logo } from './assets/logo.svg'

function useMedia(query) {
  let [matches, setMatches] = useState(
    window.matchMedia(query).matches
  )
  // cDM, cDU
  useEffect(
    () => {
      let media = window.matchMedia(query);
      if (media.matches !== matches) {
        setMatches(media.matches)
      }
      let listener = () =>
        setMatches(media.matches)
      media.addListener(listener)
      return () => media.removeListener(listener)
    },
    [query]
  )
  return matches
}

function App(props) {
  const { classes, savePairs, saveMembers } = props
  const { data } = Data
  const isMobile = useMedia("(max-width: 900px)")

  useEffect(() => {
    const pairData = createPairs(data, true)
    savePairs(pairData)

    // create dictionary with id as keys
    const memberData = {}
    data.forEach(member => {
      memberData[member.id] = member
    })
    saveMembers(memberData)
  }, [])

  return (
    <Router>
      <div className={classes.container}>
        <Modal />
        <div className={classes.navbar}>
          { !isMobile &&
          <div className={classes.navContainer}>
            <Logo className={classes.logo}/>
            <Link to="/" className={classes.link}>Browse</Link>
            <Link to="/gallery" className={classes.link}>Gallery</Link>
            <Link to="/about" className={classes.link}>About</Link>
          </div>
          }
          { isMobile &&
          <div className={classes.navContainer}>
            <Link to="/" className={classes.link}>Browse</Link>
            <Link to="/gallery" className={classes.link}>Gallery</Link>
            <Link to="/about" className={classes.link}>About</Link>
          </div>
          }
        </div>
        <Route render={({ location }) => {
          const { pathname, key } = location
          // console.log(pathname, key)
          return (
            <TransitionGroup component={null}>
              <Transition
                key={key}
                appear={true}
                onEnter={(node, appears) => play(pathname, node, appears)}
                onExit={(node, appears) => exit(node, appears)}
                timeout={{enter: 750, exit: 150}}
              >
                <div className={classes.body}>
                  <Switch>
                    <Route path="/" exact component={Body} />
                    <Route path="/about" exact component={About} />
                    <Route path="/gallery" exact component={Gallery} />
                  </Switch>
                </div>
              </Transition>
            </TransitionGroup>
          )
        }}/>
      </div>
    </Router>
    )
}

const styles = {
  container: {
    backgroundColor: 'rgb(247, 246, 246)',
    fontFamily: '"Rubik",sans-serif',
    position: 'relative',
  },
  body: {
    width: '90%',
    margin: 'auto',
    maxWidth: '1200px',
    fontFamily: '"Rubik",sans-serif',
    minHeight: 'calc(100vh - 60px)',
    paddingTop: '60px',
  },
  navbar: {
    width: '100%',
    height: '60px',
    borderBottom: '1px solid #eee',
    backgroundColor: 'white',
    display: 'flex',
    alignItems: 'center',
    position: 'fixed',
    zIndex: 2,
  },
  navContainer: {
    width: '90%',
    margin: '0 auto',
    maxWidth: 1200,
    display: 'flex',
    alignItems: 'center',
  },
  link: {
    textDecoration: 'none',
    fontWeight: 400,
    padding: 6,
    fontSize: 18,
    borderRadius: '4px',
    '&:visited': {
      color: 'black',
    },
    '&:hover': {
      backgroundColor: '#e5e5e5',
    },
  },
  logo: {
    width: '50px',
    height: '50px',
  },
}

const mapDispatchToProps = dispatch => ({
  savePairs: data => dispatch(saveP(data)),
  saveMembers: data => dispatch(saveMem(data))
})

export default connect(null, mapDispatchToProps)(injectSheet(styles)(App))
