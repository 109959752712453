import React from 'react'
import injectSheet from 'react-jss'

import PairContainer from '../containers/Pair'

function Pair(props) {
  const { classes, data } = props
  return (
    <div className={classes.container}>
      <PairContainer data={data} />
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    marginRight: '8px',
    '&:hover': {
      transform: 'translateY(-1px)',
      // boxShadow: '0px 3px 6px rgba(0,0,0,0.1)',
    },
  }
}
export default injectSheet(styles)(Pair)