import React from 'react'
import injectSheet from 'react-jss'

import Pair from './Pair'
import { convertToRoman, createPairs } from '../utils'

function Row(props) {
  const { classes, data, gen } = props

  if (data.length > 0) {
    const pairs = createPairs(data , false)
    // // sort pairs by the oldest member in each pair
    const sortedRow = []
    for (let key in pairs) {
      sortedRow.push(pairs[key])
    }
    sortedRow.sort(
      function(a, b) {
        if (a[0].bornIn > b[0].bornIn) return 1
        if (a[0].bornIn < b[0].bornIn) return -1
        return 0
        }
    )
    const genRoman = convertToRoman(gen) 
    return (
      <div className={classes.container}>
        <span className={classes.tag}>{`generation ${genRoman}`}</span>
        <div className={classes.row}>
          {sortedRow.map((pair) => {
            return (
              <Pair data={pair} key={pair[0].id}/>
            )
          })}
        </div>
      </div>
    )
  }
  return null
}

const styles = {
  container: {
    paddingBottom: '28px',
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'flex-start',
  },
  row: {
    display: 'flex',
    flexWrap: 'wrap',
    '@media (max-width: 1200px)': {
      flexWrap: 'nowrap',
      overflowX: 'auto',
      width: '100%',
      '&::-webkit-scrollbar': {
        height: '8px',
        borderRadius: '8px',
      },
      '&::-webkit-scrollbar-track': {
        borderRadius: '5px',
        background: '#e9e9e9',
        opacity: 0.5,
      },
      '&::-webkit-scrollbar-thumb': {
        background: '#959595',
        borderRadius: '5px',
      }
    },
  },
  tag: {
    textTransform: 'uppercase',
    borderRadius: '4px',
    backgroundColor: '#4444ee',
    color: '#fff',
    fontSize: '10px',
    fontWeight: 900,
    lineHeight: '1em',
    padding: '5px 7px',
    verticalAlign: 'middle',
    marginBottom: '8px',
    letterSpacing: '0.7px',
    cursor: 'default',
  },
}
export default injectSheet(styles)(Row)