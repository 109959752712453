import classNames from 'classnames'
import { connect } from 'react-redux'
import React, { useEffect, useState } from 'react'
import injectSheet from 'react-jss'
import { CSSTransition } from 'react-transition-group'
import { closeModal, openModal } from '../actions/index'
import { ReactComponent as CloseIcon } from '../assets/close.svg'
import ModalMember from '../components/ModalMember'

function useMedia(query) {
  let [matches, setMatches] = useState(
    window.matchMedia(query).matches
  )
  // cDM, cDU
  useEffect(
    () => {
      let media = window.matchMedia(query);
      if (media.matches !== matches) {
        setMatches(media.matches)
      }
      let listener = () =>
        setMatches(media.matches)
      media.addListener(listener)
      return () => media.removeListener(listener)
    },
    [query]
  )
  return matches
}

function renderChildren(children, classes, pairs, isMobile, openModal) {
  return (
    <React.Fragment>
      {children.map((child, index) => {
        let image
        let reduceOpacity = false
        try {
          image = require(`../assets/${child.id}.jpg`)
        } catch (err) {
          image = require(`../assets/placeholder.jpg`)
          reduceOpacity = true
          }
        // const isMale = data.gender === 'M'
        if (typeof child !== 'string') {
          return (
            <div key={child.id} className={classNames(classes.childBox, { isMobile })} onClick={() => openModal(pairs[child.pairID])}>
              <img className={classNames(classes.profilePic, { isMobile, reduceOpacity })} src={image} alt="profile" />
              <div className={classNames(classes.childName, { isMobile })}>{child.first}</div>
            </div>
          )
        }
        reduceOpacity = true
        return (
          <div key={index} className={classes.childBox}>
            <img className={classNames(classes.profilePic, { isMobile, reduceOpacity })} src={image} alt="profile" />
            <div className={classes.childName}>{child}</div>
          </div>
        )
      })}
    </React.Fragment>
  )
}

function Modal(props) {
  let { classes, closeModal, isOpen, modalData, members, pairs, openModal } = props
  const isMobile = useMedia("(max-width: 900px)")

  function handleClick(e) {
    closeModal()
  }
  function handleChild(e) {
    e.stopPropagation()
  }


  if (modalData.length === 0) return null

  const children = modalData[0].children.map(child => {
    // returns name as string if child is not in db
    if (child in members) {
      return members[child]
    }
    return child
  })
  return (
    <CSSTransition in={isOpen} timeout={400} unmountOnExit>
      <div
        className={classNames(classes.overlay, { isOpen })} 
        onClick={handleClick}
      >
        <div
          className={classNames(classes.body, { isOpen })}
          onClick={handleChild}
        >
          <div className={classes.close} onClick={handleClick}>
            <CloseIcon className={classes.closeIcon} />
          </div>
          {
            isMobile ?
              <div className={classes.mobileMain}>
                {/* code is repeated as i choose not to refactor the code to save time */}
                {modalData.map((member) => {
                    const parents = member.parents.map((parent) => {
                      if (parent in members) {
                        return (
                          members[parent]
                        )
                      }
                      return parent
                    })
                    return (
                      <ModalMember
                        key={member.id}
                        member={member}
                        parents={parents}
                        pairs={pairs}
                        openModal={openModal}
                        isMobile={isMobile}
                      />
                    )
                  })}
                <div className={classes.childrenMobile}>
                  <div className={classes.childerHeaderMobile}>Children</div>
                  <div className={classes.childrenBodyMobile}>
                  {renderChildren(children, classes, pairs, isMobile, openModal)}
                  </div>
                </div>
              </div>
            :
            <div className={classes.main}>
              <div className={classes.mainBody}>
                {modalData.map((member) => {
                  const parents = member.parents.map((parent) => {
                    if (parent in members) {
                      return (
                        members[parent]
                      )
                    }
                    return parent
                  })
                  return (
                    <ModalMember
                      key={member.id}
                      member={member}
                      parents={parents}
                      pairs={pairs}
                      openModal={openModal}
                      isMobile={isMobile}
                    />
                  )
                })}
              </div>
              <div className={classes.children}>
                <div className={classes.childrenContainer}>
                  <div className={classes.childrenHeader}>Children</div>
                  <div className={classes.childrenBody}>
                    {renderChildren(children, classes, pairs, isMobile, openModal)}
                  </div>
                </div>
              </div>
            </div>
          }
        </div>
      </div>
    </CSSTransition>

  )
}

const styles = {
  overlay: {
    position: 'fixed',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    transform: 'scale(1)',
    top: 0,
    left: 0,
    height: '100vh',
    width: '100vw',
    background: 'rgba(0,0,0,0.75)',
    zIndex: 9,
    animation: 'moveDown .5s ease-in',
    '&.isOpen': {
      animation: 'moveUp .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards',
    },
  },
  body: {
    background: 'linear-gradient(239deg, #fdfbfb, #ebedee)',
    position: 'relative',
    animation: 'moveDown .5s ease-in',
    '&.isOpen': {
      animation: 'moveUp .5s cubic-bezier(0.165, 0.840, 0.440, 1.000) forwards',
    },
  },
  '@keyframes moveUp': {
    '0%': {
      transform: 'translateY(650px)',
      opacity: 0,
    },
    '100%': {
      transform: 'translateY(0)',
      opacity: 1,
    },
  },
  '@keyframes moveDown': {
    '0%': {
      // transform: 'translateY(0)',
    },
    '100%': {
      transform: 'translateY(650px)',
      opacity: 0,
    },
  },
  close: {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    position: 'absolute',
    top: '-10px',
    right: '-10px',
    background: '#d50000',
    borderRadius: '50%',
    width: '25px',
    height: '25px',
    cursor: 'pointer',
    zIndex: 9,
  },
  closeIcon: {
    color: '#fff',
    height: '14px',
    width: '14px',
  },
  main: {
    display: 'flex',
    width: 960,
    height: 760,
    maxHeight: 'calc(100vh - 30px)',
    overflowY: 'auto',
  },
  mobileMain: {
    width: '90vw',
    height: '90vh',
    display: 'flex',
    flexDirection: 'column',
    overflow: 'auto',
  },
  mainBody: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    width: 760,
    height: 720,
  },
  children: {
    width: 260,
    position: 'relative',
  },
  member: {
    width: '45%',
  },
  childrenContainer: {
    position: 'absolute',
    width: 300,
    left: '-55px',
    marginTop: 40,
    fontFamily: '"Rubik",sans-serif',
  },
  childrenBody: {
    padding: '20px 20px',
    display: 'flex',
    flexWrap: 'wrap',
  },
  childrenHeader: {
    fontSize: '18px',
    textAlign: 'center',
    fontWeight: 600,
  },
  childBox: {
    width: 125,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    flexDirection: 'column',
    marginBottom: '9px',
    borderRadius: '12px',
    cursor: 'pointer',
    transition: 'all 0.3s ease-in-out',
    '&:hover': {
      backgroundColor: '#e0e0e0',
    },
    '&.isMobile': {
      width: 68,
      flex: '0 0 auto',
      marginBottom: '0px',
    },
  },
  profilePic: {
    borderRadius: '50%',
    width: 96,
    height: 96,
    border: '1px solid black',
    marginBottom: 6,
    marginTop: '9px',
    opacity: 0.9,
    '&.reduceOpacity': {
      opacity: 0.5,
    },
    '&.isMobile': {
      width: 48,
      height: 48,
      marginTop: '4px',
    },
  },
  childName: {
    textTransform: 'uppercase',
    fontSize: '14px',
    height: '28px',
    textAlign: 'center',
    '&.isMobile': {
      fontSize: '10px',
    },
  },
  childrenBodyMobile: {
    display: 'flex',
    overflowX: 'auto',
    marginLeft: '6px',
    '&::-webkit-scrollbar': {
      height: '4px',
      borderRadius: '8px',
    },
    '&::-webkit-scrollbar-track': {
      borderRadius: '5px',
      background: '#e9e9e9',
      opacity: 0.5,
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#959595',
      borderRadius: '5px',
    }
  },
  childrenMobile: {
    width: 320,
    margin: '0 auto',
    paddingTop: 4,
  },
  childerHeaderMobile: {
    fontWeight: 600,
    marginBottom: 2,
    fontSize: '12px',
    display: 'inline',
    marginLeft: '14px',
  },
}

const mapStateToProps = ({ modal, userData }) => {
  const { isOpen, modalData } = modal
  const { members, pairs } = userData
  return ({ isOpen, modalData, members, pairs })
}

const mapDispatchToProps = dispatch => ({
  closeModal: () => dispatch(closeModal()),
  openModal: (data) => dispatch(openModal(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(injectSheet(styles)(Modal))
