import classNames from 'classnames'
import { connect } from 'react-redux'
import React, { useState } from 'react'
import injectSheet from 'react-jss'
import { ReactComponent as SearchIcon } from '../assets/search.svg'

import Row from '../components/Row'
import Data from '../data.json'

function Body(props) {
  const { data } = Data
  const { classes } = props
  const [ searchValue, setSearchValue ] = useState('')
  const [ searchStatus, setSearchStatus ] = useState(false)
 
  function handleChangeInput(e) {
    setSearchValue(e.target.value)
  }

  // eslint-disable-next-line
  const searchResults = data.map((member) => {
    const fullName = `${member.last} ${member.first}`.toUpperCase()
    if (fullName.indexOf(searchValue.toUpperCase()) > -1) {
      return member
    }
  }).filter((res) => res !== undefined)


  // get number of generations
  let numberOfGenerations = 0
  const generations = []
  searchResults.forEach(member => {
    numberOfGenerations = Math.max(numberOfGenerations, member.gen)
  })
  for (let i = 0; i < numberOfGenerations; i++) {
    generations.push([])
  }
  // split each generation by row
  searchResults.forEach(member => {
    const gen = member.gen - 1
    generations[gen].push(member)
  })
  const generatedRows = generations.map((gen, index) => {
    return (
      <Row data={gen} gen={index+1} key={index} />
    )
  })
  return (
    <div className="animations">
      <div className={classes.header}>
        <div className={classes.headerRow}>
          <div className={classes.leftHeader}>
            <h1 className={classes.title}>Ancestry Tree</h1>
            <span className={classes.subtitle}>Explore the different generations</span>
          </div>
          <div className={classes.rightHeader}>
            <span className={classNames(classes.searchContainer, { searchStatus })}>
              <span className={classes.iconContainer}>
                <SearchIcon className={classes.search} />
              </span>
              <input
                className={classes.input}
                type="text"
                value={searchValue}
                placeholder="Search..."
                onChange={handleChangeInput}
                onFocus={() => setSearchStatus(true)}
                onBlur={() => setSearchStatus(false)}
              />
            </span>
          </div>
        </div>
      </div>
      {generatedRows}
    </div>
  )
}

const styles = {
  header: {
    padding: '20px 0',
  },
  headerRow: {
    display: 'flex',
    justifyContent: 'space-between',
    '@media (max-width: 1200px)': {
      flexDirection: 'column',  
    },
  },
  title: {
    display: 'inline',
    fontSize: '24px',
    fontWeight: 400,
    '@media (max-width: 1200px)': {
      margin: '0',
      fontSize: '18px',
    },
  },
  subtitle: {
    paddingLeft: '10px',
    fontSize: '18px',
    color: '#555555',
    '@media (max-width: 1200px)': {
      padding: 0,
      fontSize: '14px',
    },
  },
  input: {
    backgroundColor: 'inherit',
    border: 'none',
    fontSize: '16px',
    width: '190px',
    height: '16px',
    margin: '10px 6px',
    '@media (max-width: 1200px)': {
      fontSize: '12px',
      margin: '6px 6px',
    },
    '&:focus': {
      outline: 0,
    },
  },
  searchContainer: {
    border: '1px solid #e0e0e0',
    borderRadius: '6px',
    opacity: 0.7,
    boxShadow: '0 2px 4px rgba(50,50,93,0.11), 0 1px 2px rgba(0,0,0,0.08)',
    display: 'flex',
    '@media (max-width: 1200px)': {
      marginTop: '8px',  
    },
    '&.searchStatus': {
      opacity: 1,
      borderColor: '#4444ee',
    },
  },
  iconContainer: {
    backgroundColor: '#959595',
    width: 38,
    borderTopLeftRadius: 6,
    borderBottomLeftRadius: 6,
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    boxShadow: '0 2px 4px rgba(50,50,93,0.11), 0 1px 2px rgba(0,0,0,0.08)',
  },
  search: {
    width: 16,
    height: 16,
    color: 'rgb(247, 246, 246)',
    '@media (max-width: 1200px)': {
      width: 14,
      height: 14,
    },
  },
  leftHeader: {
    '@media (max-width: 1200px)': {
      display: 'flex',
      flexDirection: 'column',  
    },
  },
}

const mapStateToProps = state => {
  const { userData } = state
  return ({
    members: userData.members,
    pairs: userData.pairs,
  })
}

export default connect(mapStateToProps, null)(injectSheet(styles)(Body))