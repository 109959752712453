import classNames from 'classnames'
import React from 'react'
import injectSheet from 'react-jss'

// const image = require(`./chanjunwei.jpg`)
function Member(props) {
  const { classes, data, onClickPair, setHover, isHovered } = props
  let image
  try {
    image = require(`../assets/${data.id}.jpg`)
  } catch (err) {
    image = require(`../assets/placeholder.jpg`)
   }
  const isMale = data.gender === 'M'

  return (
    <div
      key={data.id}
      className={classNames(classes.container, { isHovered })}
      onClick={() => onClickPair(data.pairID)}
      onMouseEnter={() => setHover(true)}
      onMouseLeave={() => setHover(false)}
    >
      <div className={classNames(classes.top, { isMale })}>
        <img className={classes.profilePic} src={image} alt="profile" height={96} width={96}/>
      </div>
      <div className={classes.bottom}>
        <span className={classNames(classes.textLabel)}>
          <span>{data.last}</span>
          <span>{data.first}</span>
        </span>
      </div>
    </div>
  )
}

const styles = {
  container: {
    display: 'flex',
    flexDirection: 'column',
    cursor: 'pointer',
    backgroundColor: '#fbfcfd',
    borderRadius: '6px',
    boxShadow: '0 1px 3px rgba(0,0,0,0.12), 0 1px 2px rgba(0,0,0,0.24)',
    transition: '0.3s cubic-bezier(.25,.8,.25,1)',
    width: '128px',
    height: '168px',
    marginBottom: '8px',
    '&.isHovered': {
      boxShadow: '0 14px 28px rgba(0,0,0,0.25), 0 10px 10px rgba(0,0,0,0.22)',
    },
  },
  textLabel: {
    fontSize: '12px',
    display: 'flex',
    flexDirection: 'column',
    margin: '0px 4px',
    borderRadius: '16px',
    alignItems: 'center',
    textTransform: 'uppercase',
    textAlign: 'center',
    height: '44px',
  },
  top: {
    display: 'flex',
    justifyContent: 'center',
    padding: '10px',
    backgroundColor: '#ffebee',
    height: '28px',
    '&.isMale': {
      backgroundColor: '#e8eaf6',
    },
  },
  bottom: {
    marginTop: '73px',
    height: '100%',
  },
  profilePic: {
    borderRadius: '50%',
    border: '2px solid white'
  },
}
export default injectSheet(styles)(Member)