import { connect } from 'react-redux'
import React, { useState } from 'react'
import injectSheet from 'react-jss'

import { openModal } from '../actions/index'
import Member from '../components/Member'

function Pair(props) {
  const [isHovered, setHover] = useState(false)
  const { classes, pairs, data, openModal } = props
  const len = data.length - 1

  function onClickPair(pairID) {
    console.log('clicked on', pairID)
    openModal(pairs[pairID])
  }

  return (
    <React.Fragment>
      {data.map((member, index) => {
        return (
          <div key={index} className={classes.test}>
            <Member data={member} onClickPair={onClickPair} setHover={setHover} isHovered={isHovered}/>
            {len !== index && <div className={classes.link}>
            <div className={classes.bridge} /></div>}
          </div>
        )
      })}
    </React.Fragment>
  )
}

const styles = {
  link: {
    width: '8px',
    display: 'flex',
    alignItems: 'center',
  },
  bridge: {
    backgroundColor: '#9e9e9e',
    height: '5px',
    width: '100%',
  },
  test: {
    display: 'flex',
  },
}

const mapStateToProps = ({ userData }) => {
  return ({
    pairs: userData.pairs,
  })
}

const mapDispatchToProps = dispatch => ({
  openModal: (data) => dispatch(openModal(data))
})

export default connect(mapStateToProps, mapDispatchToProps)(injectSheet(styles)(Pair))