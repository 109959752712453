export const savePairs = pairs => ({
  type: 'SAVE_PAIRS',
  pairs
})

export const saveMembers = members => ({
  type: 'SAVE_MEMBERS',
  members
})

export const openModal = data => ({
  type: 'OPEN_MODAL',
  data
})

export const closeModal = () => ({
  type: 'CLOSE_MODAL'
})