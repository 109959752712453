const modal = (state = {
  isOpen: false,
  modalData: [],
}, action) => {
  switch (action.type) {
    case 'OPEN_MODAL':
      return {
        modalData: action.data,
        isOpen: true
      }
    case 'CLOSE_MODAL':
      return {
        modalData: state.modalData,
        isOpen: false
      }
    default:
      return state
  }
}

export default modal