const defaultUserData = {
  pairs: {},
  members: {},
}

const userData = (state = defaultUserData, action) => {
  switch (action.type) {
    case 'SAVE_PAIRS':
    return {
      pairs: action.pairs,
      members: state.members
    }
    case 'SAVE_MEMBERS':
      return {
        pairs: state.pairs,
        members: action.members
      }
    default:
      return state
  }
}

export default userData