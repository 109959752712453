import React from 'react'
import injectSheet from 'react-jss'

function About(props) {
  const { classes} = props
  return (
    <div className={classes.container}>Coming Soon</div>
  )
}

const styles = {
  container: {
    marginTop: '15px',
  },
}

export default injectSheet(styles)(About)